<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 380 : '100%'"
    class="pl-2 pr-2 pt-2 pb-2"
    app
    @input="(val) => $emit('aside', val)"
  >
    <p>Select Day range you want to generate:</p>
    <v-date-picker
      v-model="dates"
      range
      color="primary"
    ></v-date-picker>

    <div class="d-flex justify-center mt-5">
      <v-btn
        color="primary"
        :loading="loading"
        @click="handleSubmit"
      >
        Generate
      </v-btn>
    </div>

    <v-alert
      v-for="err in errors"
      :key="err"
      type="warning"
    >
      {{ err }}
    </v-alert>
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeSlotsFromRule } from '@api/product'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'

export default {
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    sku: {
      type: Object,
      required: true,
    },
    resource: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const dates = ref([dayjs().format('YYYY-MM-DD'), dayjs().add(1, 'month').format('YYYY-MM-DD')])

    const handleSubmit = async () => {
      const from = dates.value[0] < dates.value[1] ? dates.value[0] : dates.value[1]
      const to = dates.value[0] < dates.value[1] ? dates.value[1] : dates.value[0]
      const data = {
        slot_rule_id: props.resource.id,
        from_date: dayjs(from).startOf('day').toString(),
        to_date: dayjs(to).endOf('day').toString(),
      }

      loading.value = true
      const request = storeSlotsFromRule(data)
      await request
        .then(res => {
          emit('changed', res.data.data)
          emit('created', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      dates,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
